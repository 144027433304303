import React from "react";

// Customizable Area Start
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
} from "@mui/material";
import { cameraImage, photoFace } from "./assets";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from './NavigationMenu';
import Setting from "../../settings2/src/Settings2.web";
import SideMenuController, {
    configJSON,
} from "./SideMenuController.web";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HelpCentre from "../../helpcentre/src/HelpCentre.web";
import UserProfile from "../../user-profile-basic/src/UserProfileBasicBlock.web";
// Customizable Area End

export default class SideMenu extends SideMenuController {

    // Customizable Area Start
    renderRightSection = () => {
        switch (this.state.selectedSection) {
            case `${configJSON.editProfile}`:
                return <UserProfile navigation={undefined} id={""} uploadImageState={this.state.uploadImageState} uploadImageFunction={this.handleUploadImage}/>;
            case `${configJSON.payments}`:
                return <div>{configJSON.testpayment}</div>;
            case `${configJSON.tripHistory}`:
                return <div>{configJSON.testsection2}</div>;
            case `${configJSON.favLocation}`:
                return <div>{configJSON.testsection3}</div>;
            case `${configJSON.notifications}`:
                return <div>{configJSON.testsection4}</div>;
            case `${configJSON.helpCenter}`:
                return <div><HelpCentre navigation={this.props.navigation} id={""} /></div>;
            case `${configJSON.settings}`:
                return <Setting navigation={undefined} id={""} />;
        }
    };

    handleData =  () => {
        const driverMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.payments}`, icon: <CreditCardOutlinedIcon /> },
            { text: `${configJSON.notifications}`, icon: <NotificationsNoneIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        const riderMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.tripHistory}`, icon: <DateRangeIcon /> },
            { text: `${configJSON.favLocation}`, icon: <StarBorderIcon /> },
            { text: `${configJSON.notifications}`, icon: <NotificationsNoneIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        let data = this.state.idUserRole === "1" ? riderMenuItems : driverMenuItems
        return data;
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                <NavigationMenu navigation={undefined} id={""} />
                {
                    this.state.selectedSection !== `${configJSON.settings}` && <Header>
                        <ProfileContainer data-testId={"visibleImageModalID"} onClick={this.handleUploadImage}>
                            <CameraIcon src={cameraImage.default} alt={configJSON.camera} />
                            <FaceIcon src={photoFace.default} alt={configJSON.camera} />
                            <TypographyEdit >{configJSON.editProfile}</TypographyEdit>
                        </ProfileContainer>

                        <TypographyName >{configJSON.name}</TypographyName>
                    </Header>
                }

                <IconButtonForToogle onClick={this.handleToggleSidebar} style={{ display: window.innerWidth <= 960 ? `${configJSON.block}`: `${configJSON.none}` }}>
                    <MenuIcon />
                </IconButtonForToogle>

                <FullContainer sx={{ marginTop: this.state.selectedSection == `${configJSON.settings}` ?  `${configJSON.px40}`: `${configJSON.px0}` }}>
                    <Sidebar
                        style={{
                            display:
                                window.innerWidth > 960 || this.state.isSidebarOpen
                                    ? `${configJSON.block}`
                                    : `${configJSON.none}`,
                        }}
                    >
                        <List component="nav" >
                            {this.handleData().map((item: any) => (
                                <ListItemLeft
                                    key={item.text}
                                    onClick={() => this.handleSectionChange(item.text)}
                                >
                                    <ListItemIconSideBar>
                                        <StyledIcon style={{
                                            color: this.state.selectedSection === item.text
                                                ? `${configJSON.color1}`
                                                : `${configJSON.color2}`,
                                        }}>
                                            {item.icon}
                                        </StyledIcon>
                                    </ListItemIconSideBar>
                                    <ListItemText
                                        primary={item.text}
                                        style={{
                                            color:
                                                this.state.selectedSection === item.text
                                                    ? `${configJSON.color1}`
                                                    : `${configJSON.color2}`,
                                        }}
                                    />
                                </ListItemLeft>
                            ))}
                        </List>
                    </Sidebar>
                    <BoxList>{this.renderRightSection()}</BoxList>
                </FullContainer>

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const FullContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
        flexDirection: "column",
        marginTop: "30px",
    }
});

const BoxList = styled(Box)({
    width: '75%',
    "@media (max-width: 960px)": {
        width: '80%',
        margin: "0px auto"
    }
});

const ProfileContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1.5px solid #ffffff",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    marginRight: "20px",

});

const CameraIcon = styled("img")({
    width: "15px",
    height: "15px",
    marginBottom: "11px",
    zIndex: 2,
    position: "absolute"
});

const FaceIcon = styled("img")({
    width: "58px",
    height: "58px",
    position: "relative",
    zIndex: 1,
    borderRadius: '50%'
});

const TypographyName = styled(Typography)({
    fontSize: "24px"
});
const TypographyEdit = styled(Typography)({
    fontSize: '8px',
    color: '#ffffff',
    zIndex: 2,
    position: "absolute",
    marginTop: '20px',
});

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#87C122',
    padding: '33px',
    borderRadius: '10px',
    margin: '20px 35px',
});

const IconButtonForToogle = styled(IconButton)({
    marginTop: '6px',
    marginLeft: '10px',
    display: "none",
    "@media (max-width: 960px)": {
        display: "flex",
    },
});

const Sidebar = styled(Box)({
    width: '20%',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    display: 'flex',
    justifyItems: 'center',
    margin: '0px 40px',
    '@media (max-width: 960px)': {
        margin: '20px auto',
        width: '80%',
        marginLeft: '30px'
    },
});

const StyledIcon = styled("div")({
    color: "#0F172A",
    margin: '0 15 0 13'
});

const ListItemLeft = styled(ListItem)({
    padding: '18px 33px',
    alignItems: 'center',
    cursor: 'pointer',
});

const ListItemIconSideBar = styled(ListItemIcon)({
    minWidth: '37px',
});

// Customizable Area End
