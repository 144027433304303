import React, { Component } from "react";
import {Logo} from '../../blocks/email-account-registration/src/assets'
import { Box, Button, TextField, Checkbox, FormControlLabel, styled, Typography, LinearProgress, Radio, RadioGroup, FormControl, FormLabel, IconButton ,InputAdornment, Dialog, DialogActions,DialogContent, DialogTitle,  useMediaQuery, useTheme} from "@mui/material";
import { fileUpload,dropdown} from "../../blocks/email-account-registration/src/assets";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CSSProperties } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface MyFormProps {
  initialValues: any; // Replace 'any' with the type of your initial values
  Submit1: (values: any) => void; // Replace 'any' with the type of your form values
  back:() => void;

}

interface MyFormState {
  ModalOpen: boolean;
  optionsList1: string[];
  isFocusmobNo:boolean;
  isFocusDLicense:boolean;
  isFocusInsuranceNo:boolean;
  isFocusInsuranceNoDoc:boolean;
  isFocusDMVDoc:boolean;
  isFocusDLicensePic:boolean;
  fileInsuranceNoDoc:string;
  fileDMVDoc:string;
  fileDLicensePic:string;
  isFocusAddreq:boolean;
}


const LogoContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
  });
  
  const LogoTextBottom1 = styled("div")({
    marginTop: "16px",
    fontWeight: 400,
    fontSize: "18px",
    color: "white",
  });
  
  const RoadText = styled(Typography)({
    color: "white",
    fontWeight: 400,
    fontSize: "30px",
    marginTop: "40px",
  });
  
  const ProgressBarContainer = styled("div")({
    width: "100%",
    margin: "20px 0",
  });
  
  const Heading = styled(Typography)({
    fontWeight: 400,
    fontSize: "30px",
    color: "#1D6FB8",
    width: "100%",
    marginBottom: "16px",
  });
  
  const CustomLinearProgress = styled(LinearProgress)({
    height: "10px",
    borderRadius: "5px",
    backgroundColor: "#E2E8F0",
    '& .MuiLinearProgress-bar': {
        backgroundColor: "#4caf50",
    },
  });
  const validationschema = Yup.object().shape({
    mobNo: Yup.string()
      .required('Mobile number is required')
      .matches(/^\d{10,12}$/, 'Mobile number must be 10 to 12 digits'),
    
      DLicense: Yup.string()
      .required('Driving license number is required')
      .matches(/^\d+$/, 'Driving license number must contain only digits') // Ensure only digits
      .min(4, 'Driving license number must be at least 4 digits')
      .max(15, 'Driving license number cannot be more than 15 digits'),
  
    InsuranceNo: Yup.string()
      .required('Insurance number is required')
      .matches(/^\d+$/, 'Insurance number must contain only digits') // Ensure only digits
      .min(4, 'Insurance number must be at least 4 digits')
      .max(15, 'Insurance number cannot be more than 15 digits'),
  
      InsuranceNoDoc: Yup.mixed<File>()
      .required('Insurance document is required')
      .test('fileType', 'Only PDF or JPEG, JPG images are allowed', (value) => {
        if (!value) return false;
        const file = value as File;
        const validTypes = [
          'application/pdf',  // PDF files
          'image/jpeg',  // JPEG images
          'image/jpg',   // JPG images
        ];
        return validTypes.includes(file.type);
      }),
    
    DMVDoc: Yup.mixed<File>()
      .required('DMV document is required')
      .test('fileType', 'Only PDF or JPEG, JPG images are allowed', (value) => {
        if (!value) return false;
        const file = value as File;
        const validTypes = [
          'application/pdf',  // PDF files
          'image/jpeg',  // JPEG images
          'image/jpg',   // JPG images
        ];
        return validTypes.includes(file.type);
      }),
    
    DLicensePic: Yup.mixed<File>()
      .required('Driving license picture is required')
      .test('fileType', 'Only PDF or JPEG, JPG images are allowed', (value) => {
        if (!value) return false;
        const file = value as File;
        const validTypes = [
          'application/pdf',  // PDF files
          'image/jpeg',  // JPEG images
          'image/jpg',   // JPG images
        ];
        return validTypes.includes(file.type);
      }),
    
    Addreq: Yup.string()
      .required('Additional request is required')
      .oneOf(['None','Wheelchair', 'Gurney'], 'Additional request must be either "wheelchair" or "gurney"'),
  });
  
 
  const webStyle = { 
    InnerFirst:{color:'#1D6FB8',fontSize:'20px',fontWeight:400,marginBottom:'15px'},
    ErrorMessage:{ color:'#DC2626', fontSize: '12px',fontWeight:'400', marginTop: '5px',marginBottom:'5px' },
    FieldHeader2:{
     fontSize: "14px",
     fontWeight: 700,
     paddingBottom:'5px',
    },
    ButtonStyle2:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    ButtonStyle22:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
    marginBottomm:{ marginBottom: "15px" },
    InputFIeldStyle:{width:'100%',},
    InputFIeldStyle2:{width:'100%',height:'44px',borderRadius:'8px'},
    FieldHeader:{
     fontSize: "14px",
     fontWeight: 700,
     paddingBottom:'5px',
   MarginTop:'5px'
    },
    ButtonStyle3:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    ButtonStyle23:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
    marginBottomm3:{ marginBottom: "15px" },
    InputFIeldStyle3:{width:'100%',height:'44px',borderRadius:'8px'},
    
    ButtonStyle:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    marginBottomm4:{ marginBottom: "15px" },
    InputFIeldStyle4:{width:'100%',height:'44px',borderRadius:'8px'},
    dotStyle:{
      paddingLeft: '10px',
      fontSize: '16px',
      fontWeight: '400',
      whiteSpace: 'nowrap',      
      overflow: 'hidden',       
      textOverflow: 'ellipsis',
      width: '85%',             
    }
   };
   const TextFieldCustom = styled(TextField)({
    width: '100%',
    height: "40px",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
  });
class MyFormComponent extends Component<MyFormProps, MyFormState> {
  state: MyFormState = {
    isFocusmobNo: false,
    isFocusInsuranceNo: false,
    ModalOpen: false,
      isFocusDLicense:false,
      isFocusInsuranceNoDoc:false,
      isFocusDMVDoc:false,
      isFocusDLicensePic:false,
      isFocusAddreq:false,
      optionsList1:['None', 'Wheelchair', 'Gurney'],
      fileInsuranceNoDoc:'',
      fileDMVDoc:'',
      fileDLicensePic:'',
  };
  constructor(props:any) {
    super(props);
  }
  handleDialogClose2=()=>{
    this.setState({ModalOpen:false})
      }
    handleDialogOpen2=()=>{
        this.setState({ModalOpen:true})
          }
          renderErrorMessage = ({ errors, touched, name, style }:any) => {
            if (errors[name] && touched[name]) {
              return (
                <div style={style}>
                  <ErrorMessage name={name} />
                </div>
              );
            }
            return null;
          };
        
  render() {
    const { initialValues, Submit1} = this.props;
  
    return (
        <Box
        style={{
          display: "flex",
          height: "100vh",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
          marginBottom: "0px",
          width: "100vw",
        }}
      >
        <div className="left-half">

          <Formik initialValues={initialValues} validationSchema={validationschema}  onSubmit={(values, { resetForm }) => {
            Submit1(values)
            // resetForm({ values });
          }}   enableReinitialize={true}>
            {({errors,touched,setFieldValue,values,resetForm}) => {
              return (<Form translate={undefined} className="custom-style">
                <Heading data-testid="signup-heading">
                  Create your E-Ryde account
                </Heading>

                <ProgressBarContainer>
                  <Typography variant="body2" style={{ fontWeight: 400, fontSize: "18px", color: "1D6FB8", marginBottom: "8px" }} data-testid="progress-step">
                    Step 2 of 4
                  </Typography>
                  <CustomLinearProgress variant="determinate" value={50} data-testid="progress-bar" />
                </ProgressBarContainer>
                <br />
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Mobile number
                  </Typography>
                  <div style={{ position: 'relative', width: '100%' }}>
  <span
    style={{
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize:'16px',
      fontWeight:'400',
      pointerEvents: 'none', // Prevent interaction with this element
      color: '#94A3B8' // Adjust color for a "placeholder-like" effect
    }}
  >
    US +1
  </span>
  <input
    id="mobNo"
    name="mobNo"
    type="tel"
    value={values.mobNo}
    placeholder="Mobile number"
    onChange={(e) => {
      setFieldValue("mobNo", e.target.value); // Update Formik's field value
      initialValues.mobNo= e.target.value
    }}
    style={{
      width: '100%',
      paddingLeft: '70px', // Add padding to make space for the US +1 text
      height: '44px', // Adjust the input height if necessary
      border: errors.mobNo && touched.mobNo ? '1px solid #F87171' : '1px solid #CBD5E1',
      boxSizing: 'border-box',
      borderRadius:'8px'
    }}
    className={this.state.isFocusmobNo ? 'input-focused' : 'input-default'}
    onFocus={() => this.setState({ isFocusmobNo: true })}
    onBlur={() => this.setState({ isFocusmobNo: true })}
    data-test-id="mobNo"
  />
   
</div>
               
                
                  {this.renderErrorMessage({
            errors,
            touched,
            name: 'mobNo',
            style: webStyle.ErrorMessage
          })}
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Driving licence number
                  </Typography>
                  <input
                    id="DLicense"
                    name="DLicense"
                    data-test-id='DLicense'
                    value={values.DLicense}
                    // className="placeholder-styled"
                    placeholder="Driving licence number"
                    type="text"
                    onChange={(e) => {
                     setFieldValue("DLicense", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusmobNo ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusmobNo: true })}
                    onBlur={() => this.setState({ isFocusmobNo: true })}
                    style={{
                      ...webStyle.InputFIeldStyle2,
                      border: errors.DLicense && touched.DLicense ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {this.renderErrorMessage({
            errors,
            touched,
            name: 'DLicense',
            style: webStyle.ErrorMessage
          })}
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Auto Insurance policy number
                  </Typography>

                  <input
                    id="InsuranceNo"
                    name="InsuranceNo"
                    data-test-id='InsuranceNo'
                    value={values.InsuranceNo}
                    // className="placeholder-styled"
                    placeholder="Auto Insurance policy number"
                    type="text"
                    onChange={(e) => {
                    setFieldValue("InsuranceNo", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusmobNo ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusInsuranceNo: true })}
                    onBlur={() => this.setState({ isFocusInsuranceNo: true })}
                    style={{
                      ...webStyle.InputFIeldStyle2,
                      border: errors.InsuranceNo && touched.InsuranceNo ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                 {this.renderErrorMessage({
            errors,
            touched,
            name: 'InsuranceNo',
            style: webStyle.ErrorMessage
          })}

                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                  Auto insurance policy document upload
                  </Typography>
                  <Field name="InsuranceNoDoc">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-InsuranceNoDoc"
                          data-test-id='InsuranceNoDoc'
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const file = event.target.files ? event.target.files[0] : null;
                          setFieldValue("InsuranceNoDoc", file);
                          if (file) {
                            setFieldValue("InsuranceFIleName", file.name);
                          }
                          }}
                        />
                        <label htmlFor="file-upload-InsuranceNoDoc">
                          <div
                          style={{
                            border: errors.InsuranceNoDoc && touched.InsuranceNoDoc ? '1px solid #F87171' : '1px solid #CBD5E1'
                          }}
                            className="button-style" 
                          >
                            {values.InsuranceFIleName && (
                              <Typography
                              style={webStyle.dotStyle}
                            >
                              {values.InsuranceFIleName}
                            </Typography>
                            )}
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                              className="button-icon"
                            />
                          </div>
                        </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="InsuranceNoDoc" component="div" />
                  </div>
                </Box>
                 <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                  DMV document upload
                  </Typography>
                  <Field name="DMVDoc">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-DMVDoc"
                          data-test-id='DMVDoc'
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const file = event.target.files ? event.target.files[0] : null;
                           setFieldValue("DMVDoc", file);
                           if (file) {
                            setFieldValue("DMVFileName", file.name);

                          }
                          }}
                        />
                        <label htmlFor="file-upload-DMVDoc">
                          <div
                            style={{
                              border: errors.DMVDoc && touched.DMVDoc ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style" 
                          >
                              {values.DMVFileName && (
                              <Typography  style={webStyle.dotStyle} >
                                {values.DMVFileName}
                              </Typography>
                            )}
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                              className="button-icon"
                            />
                          </div>
                        </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="DMVDoc" component="div" />
                  </div>
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                  Driving license picture upload
                  </Typography>
                  <Field name="DLicensePic">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-DLicensePic"
                          data-test-id='DLicensePic'

                          type="file"
                          style={{ display: 'none', }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const file = event.target.files ? event.target.files[0] : null;
                            setFieldValue("DLicensePic", file);
                            if (file) {
                              setFieldValue("DLicenseFileName", file.name);
                            }
                          }}
                        />
                         <label htmlFor="file-upload-DLicensePic">
                          <div
                            style={{
                              border: errors.DLicensePic && touched.DLicensePic ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style" 
                          >
                            {values.DLicenseFileName && (
                              <Typography style={webStyle.dotStyle}>
                                {values.DLicenseFileName}
                              </Typography>
                            )}
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                             className="button-icon"
                            />
                          </div>
                          </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="DLicensePic" component="div" />
                  </div>
                </Box> 
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                  Additional Requirements?
                  </Typography>
                
                          <div
                           data-test-id='Addreq'

                            style={{
                              border: errors.Addreq && touched.Addreq ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style2" 
                            onClick={this.handleDialogOpen2}
                          >
                            {
                              values.Addreq?( <Typography style={{ paddingLeft: '10px', fontSize: '16px', fontWeight: '400' }}>
                                {values.Addreq}
                                 </Typography>):(   <span className="spanText">
                              e.g. wheelchair user, gurney
                              </span>)
                            }
                            
                            <img
                              src={dropdown.default}  // Replace with the path to your image
                              alt="icon"
                             className="button-icon"
                            />
                          </div>
                          
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="Addreq" component="div" />
                  </div>
                  <Dialog open={this.state.ModalOpen} onClose={this.handleDialogClose2}   PaperProps={{
         style: {
          width: window.innerWidth < 600 ? '80%' : '20%', 
          maxWidth: 'none',
          borderRadius: '35px', // Apply the external class
          padding:'none'
        },
        }} className="dialog">
            <DialogContent style={{ paddingTop:'20px',paddingBottom:'20px'}}>
            {this.state.optionsList1.map((option:any) => {
               const isSelected = values.Addreq === option;
              return(  <div key={option} style={{display:'flex',alignItems:'center',  backgroundColor: isSelected ? '#ECFDF5' : 'white',paddingLeft:'20px',paddingRight:'20px'}}>
                <Checkbox
                  checked={values.Addreq === option}
                  onChange={() => { const newOptions = option; // Only one option is selected at a time
                   setFieldValue("Addreq", newOptions);
                    this.handleDialogClose2()}}
                  
                    icon={
                      <CheckBoxOutlineBlankIcon
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '7px', // Adding border radius
                          // backgroundColor: 'lightgray', // Background color when unchecked
                          // border: '1px solid gray' // Optional border for checkbox
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '28.05px', 
                          backgroundColor: '#fff', 

                          color: '#87C122',
                        }}
                      />
                    }
                />
                <label style={{color: '#3C3E49'
, borderRadius: '8px', fontSize: '18px', fontWeight:'400'}}>{option}</label>
              </div>)
      
    })}
            </DialogContent>
            
          </Dialog>
                </Box> 
                <br />
                <Button color="primary" variant="contained" style={webStyle.ButtonStyle3} data-test-id='LogIn2' fullWidth
                  type="submit">Continue</Button>
              
              </Form>)
            }}

          </Formik>

        </div>

        <div className="right-half">
          <div style={{backgroundColor:'#ECFDF5',
          height:'85%',
            width:'100%'
            }}>
        <img src={Logo.default} alt="Logo" />
        </div>
         <div className="outer-div">
         <div style={{...webStyle.InnerFirst,marginBottom:'15px'}}>
         Earn on the Road, Drive with Freedom
         </div>
         <div style={{...webStyle.InnerFirst}}>
         Non-medical transport
         </div>
         </div>
        </div>
      </Box> 
    );
  }
}

export default MyFormComponent;
