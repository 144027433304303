//Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import ResetPasswordController, { configJSON } from "./ResetPasswordController.web";
import { logo } from "./assets";
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
//Customizable Area End

export default class ResetPassword extends ResetPasswordController {
    render() {
        // Customizable Area Start
        return (
            <StyledBox>
                <LeftHalf>
                    <FormContainer>
                        <HeaderOne data-testID={"headLingId"}>
                            {configJSON.labelYourNewPass}
                        </HeaderOne>
                        <HeaderThree>{`${configJSON.enterNewPass} ${this.state.email}`}</HeaderThree>

                        {this.state.showApiError && (
                            <CheckboxValidationContainer>
                                <Typography data-testID={"errorID"}>{this.state.apiErrorMessage}</Typography>
                            </CheckboxValidationContainer>
                        )}

                        <InputWrapper>
                            <CustomLabel>{configJSON.labelYourNewPass}</CustomLabel>
                            <TextFieldCustom
                                data-testID={"passwordId"}
                                type={this.state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={this.state.password}
                                fullWidth
                                required
                                placeholder={configJSON.inputYourNewPass}
                                onChange={this.handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <CustomLabel>{configJSON.labelYourNewConfirmPass}</CustomLabel>
                            <TextFieldCustom
                                data-testID={"confirmPasswordId"}
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={this.state.confirmPassword}
                                fullWidth
                                required
                                placeholder={configJSON.inputYourNewConfirmPass}
                                onChange={this.handleConfirmPasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </InputWrapper>

                        <TypographyPsBox>
                            <TypographyPassword>
                                {configJSON.paswordMustContain}
                            </TypographyPassword>
                            <Box>
                                <ValidationText>
                                    {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
                                    {configJSON.hasUpperCase}
                                </ValidationText>
                                <ValidationText>
                                    {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
                                    {configJSON.hasLowerCase}
                                </ValidationText>
                                <ValidationText>
                                    {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
                                    {configJSON.hasNumber}
                                </ValidationText>
                                <ValidationText>
                                    {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
                                    {configJSON.hasSpecialChar}
                                </ValidationText>
                                <ValidationText>
                                    {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
                                    {configJSON.minimumChar}
                                </ValidationText>
                            </Box>
                        </TypographyPsBox>

                        <ButtonSignIn data-testID={"sumbitBtnID"} onClick={this.handleSendEmail}>
                            {this.state.btnLoader ? <CircularProgress /> : configJSON.setNewPass}
                        </ButtonSignIn>

                        <TypographyBackTo>
                            {configJSON.back}
                            <ButtonBackto onClick={this.handleNavigateToLogin}>{configJSON.signin}</ButtonBackto>
                        </TypographyBackTo>

                    </FormContainer>
                </LeftHalf>

                <RightHalf>
                    <MainImgage src={logo.default} alt="right-main" />
                    <TextBox>
                        <TypographyImgTextOne>{configJSON.yourSafeText}</TypographyImgTextOne>
                        <TypographyImgTextTwo>{configJSON.nonMedical}</TypographyImgTextTwo>
                    </TextBox>
                </RightHalf>

            </StyledBox>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledBox = styled(Box)({
    width: "100vw",
    height: "100vh",
    display: "flex",
    '@media (max-width: 960px)': {
        height: "auto",
        flexDirection: "column",
    }
});

const FormContainer = styled("div")({
    width: "500px",
    height: "100%",
    overflow: "scroll",
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: "none",
    "@media (max-width: 960px)": {
        width: "80%",
        marginTop: "20px"
    }
});

const LeftHalf = styled("div")({
    width: "50%",
    height: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
        width: "100%",
        height: "100%",
    }
});

const HeaderOne = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: "30px",
    color: "#1D6FB8",
    marginTop: "50px",
    "@media (max-width: 960px)": {
        fontSize: "20px",
        marginTop: "30px",
    }
}));

const HeaderThree = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: "16px",
    color: "#0F172A",
    margin: "10px 0px 20px 0px",
    [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        margin: "5px 0px 10px 0px",
    }
}));

const InputWrapper = styled(Box)({
    width: '100%',
    margin: '30px 0px',
});

const CustomLabel = styled(Typography)({
    backgroundColor: 'white',
    fontSize: '14px',
    color: '#334155',
    fontWeight: 700,
    marginBottom: '5px',
});

const TextFieldCustom = styled(TextField)({
    width: '100%',
    height: "40px",
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
    },
});

const CheckboxValidationContainer = styled(Box)({
    marginBottom: "24px",
    padding: "10px",
    border: "1px solid #DC2626",
    borderRadius: "4px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontSize: "14px",
    "@media (max-width: 960px)": {
        fontSize: "12px",
    }
});

const ValidationText = styled(Typography)({
    fontSize: "14px",
    color: "#000000",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
});

const Circle = styled("span")({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    fontSize: "12px",
    color: "white",
    fontWeight: "bold",
    marginRight: "8px",
});

const CircleGreen = styled(Circle)({
    backgroundColor: "#34D399",
    textAlign: "center"
});

const CircleRed = styled(Circle)({
    backgroundColor: "#DC2626",
    textAlign: "center"
});

const TypographyPsBox = styled(Typography)({
    marginBottom: '50px',
});

const TypographyPassword = styled(Typography)({
    fontSize: '16px',
    fontWeight: 400,
    margin: '40px 0px 20px 0px',
});

const ButtonSignIn = styled(Button)({
    width: '100%',
    height: '56px',
    color: '#ffffff',
    borderRadius: '10px',
    textTransform: "full-size-kana",
    backgroundColor: '#1D6FB8',
    '&:hover': {
        backgroundColor: '#1565c0'
    }
});

const TypographyBackTo = styled(Typography)({
    color: "#000000",
    textAlign: "center",
    margin: "10px 0px"
});

const ButtonBackto = styled(Button)({
    color: '#1D6FB8',
    textTransform: "full-size-kana"
});

const RightHalf = styled(Box)({
    width: "50%",
    height: "100%",
    textAlign: 'center',
    backgroundColor: "#ECFDF5",
    "@media (max-width: 960px)": {
        width: "100%",
        height: "100%",
    }
})

const TextBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
});

const MainImgage = styled('img')({
    width: '100%',
    height: '85%',
    "@media (max-width: 960px)": {
        width: '100%',
    }
});

const TypographyImgTextOne = styled(Typography)({
    color: '#1D6FB8',
    marginTop: '14px',
    fontWeight: 400,
    fontSize: '20px',
    "@media (max-width: 960px)": {
        fontSize: '15px',
    }
});

const TypographyImgTextTwo = styled(Typography)({
    color: '#1D6FB8',
    marginTop: '14px',
    fontWeight: 400,
    fontSize: '20px',
    "@media (max-width: 960px)": {
        fontSize: '15px',
    }
})
// Customizable Area End