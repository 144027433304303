import React from "react";

// Customizable Area Start
import "./EmailAccountRegistratin.css"
import SignUpDriverNextController, {
  Props
} from "./SignUpDriverNextController.web";
import Form2 from '../../../components/src/SignUpdriverStep2.web'
import Form3 from '../../../components/src/SignUpdriverStep3.web'
import Form1 from '../../../components/src/SignUpdriverStep1.web'
import {
  styled,
  Typography,
  Box,
 
  Modal,
} from '@mui/material';
const configJSON = require("./config");
import { error, retry,sucess } from "./assets";
// Customizable Area End

// Customizable Area Start
const ErrorBox = styled(Box)(({ theme }) => ({
  width: '70%',
  overflow: 'hidden',
  backgroundColor: '#FEE2E2',
  padding: '25px',
  borderRadius: '24.89px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#DC2626',
  borderLeftWidth: '7px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  [theme.breakpoints.down('md')]: {
    width: '80%', // Adjust the width for medium-sized screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%', // Adjust for smaller screens
    padding: '20px', // Decrease padding on smaller screens
  },
}));
const ErrorBox2 = styled(Box)(({ theme }) => ({
  width: '35%',
  overflow: 'hidden',
  border:'0px solid white',
  backgroundColor: '#FFFFFF',
  padding: '25px',
  borderRadius: '24.89px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  [theme.breakpoints.down('md')]: {
    width: '70%', // Adjust the width for medium-sized screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '75%', // Adjust for smaller screens
    padding: '20px', // Decrease padding on smaller screens
  },
}));

const BoxCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorImg = styled('img')(({ theme }) => ({
  width: '289.73px',
  height: '214.93px',
  [theme.breakpoints.down('sm')]: {
    width: '200px', // Adjust the image size for smaller screens
    height: '150px',
  },
}));
const SucessImg = styled('img')(({ theme }) => ({
  width: '289.73px',
  height: '214.93px',
  [theme.breakpoints.down('sm')]: {
    width: '200px', // Adjust the image size for smaller screens
    height: '150px',
  },
}));
const TypographyOops = styled(Typography)(({ theme }) => ({
  color: '#DC2626',
  fontSize: '24px',
  marginTop: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Decrease font size on smaller screens
  },
}));
const TypographySucess= styled(Typography)(({ theme }) => ({
  color: 'var(--Color-1, #87C122)',
  fontSize: '30px',
  fontWeight:'700',
  marginTop: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Decrease font size on smaller screens
  },
}));
const TypographyDouble = styled(Typography)(({ theme }) => ({
  color: '#94A3B8',
  marginTop: '30px',
  fontSize: '24px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Adjust for smaller screens
    marginTop: '20px',
  },
}));
const TypographyDouble2 = styled(Typography)(({ theme }) => ({
  color: '#475569',
  marginTop: '30px',
  fontSize: '22px',
  fontWeight:'400',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Adjust for smaller screens
    marginTop: '20px',
  },
}));
const ButtonReturn = styled(Box)(({ theme }) => ({
  background: '#DC2626',
  ':hover': {
  },
  color: '#FFFFFF',
  width: '40%',
  height: '79.07px',
  borderRadius: '16.53px',
  marginTop: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor:'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '80%', // Increase width for smaller screens
    height: '60px', // Decrease button height for smaller screens
  },
}));
const ButtonReturn2 = styled(Box)(({ theme }) => ({
  background: '#1D6FB8',
  '&:hover': {
    backgroundColor: '#559ADC',
  },
  color: '#ffff',
  width: '25%',
  height: '54px',
  borderRadius: '8px',
  marginTop: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '25%', // Increase width for smaller screens
    height: '34px', // Decrease button height for smaller screens
  },
}));
const TypographyRetry = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  marginTop: '5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px', // Decrease font size on smaller screens
  },
}));
const TypographyRetry2 = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight:'400',
  marginTop: '5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', // Decrease font size on smaller screens
  },
}));
const ImgRetry = styled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',
  margin: '0px 10px',
  [theme.breakpoints.down('sm')]: {
    width: '40px', // Adjust retry icon size for smaller screens
    height: '40px',
  },
}));
// Customizable Area End

export default class SignUpDriverNext extends SignUpDriverNextController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start 
  renderForm = () => {
    const { step } = this.state;
  
    switch (step) {
      case 1:
        return  <Form1 Submit1={this.Submit1} initialValues={this.state.initialValues1} back={this.handleBack}/>;
      case 2:
        return <Form2 Submit1={this.Submit1} initialValues={this.state.initialValues2} back={this.handleBack}/>
      case 3:
        return  <Form3 Submit1={this.Submit1} initialValues={this.state.initialValues3} back={this.handleBack}/>;
      default:
        return <h2>All forms completed!</h2>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (

       <div style={{overflowX:'hidden'}}>{this.renderForm()}
        <Modal
          open={this.state.driverOpenSucessModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testId={'closeSucessModalID'}
        >
          <ErrorBox2>
            <BoxCenter>
              <SucessImg src={sucess.default} alt="error" />
              <TypographySucess data-testId={"emptyErrorId"}>{configJSON.sucessAbove}</TypographySucess>
              <TypographyDouble2>{configJSON.Sucess}</TypographyDouble2>
              <ButtonReturn2 onClick={this.handleSucessClosee}>
                <TypographyRetry2>OK</TypographyRetry2>
              </ButtonReturn2>
            </BoxCenter>
          </ErrorBox2>
        </Modal>
        <Modal
          open={this.state.driverOpenErrorModal}
          onClose={this.handleErrorClosee}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testId={'closeOopsModalID'}
        >
          <ErrorBox>
            <BoxCenter>
              <ErrorImg src={error.default} alt="error" />
              <TypographyOops data-testId={"emptyErrorId"}>{configJSON.oops}</TypographyOops>
              <TypographyDouble>{configJSON.double}</TypographyDouble>
              <ButtonReturn onClick={this.handleErrorClosee} data-testId="closeOopsButtonID">
                <ImgRetry src={retry.default} alt="retry" />
                <TypographyRetry>{configJSON.retry}</TypographyRetry>
              </ButtonReturn>
            </BoxCenter>
          </ErrorBox>
        </Modal>

       </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
};
  // Customizable Area End
